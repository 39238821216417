import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";
import Intro from "../components/intro";
import TextImagegrid from "../components/text-imagegrid";

import introImage from "../images/neu/omellis_datenschutz.jpg";

import content from "../../data/content/privacy.html";

const PrivacyPage = () => (
  <Layout>
    <SEO title="Datenschutz" />
    <Intro image={introImage} headline="Datenschutz" />
    <TextImagegrid images={[]}>
      <div dangerouslySetInnerHTML={{ __html: content }} />
    </TextImagegrid>
  </Layout>
);

export default PrivacyPage;
